import { useRef, useEffect, RefObject } from 'react'

import Geosuggest from '~/services/Geosuggest'

import { useGoogleApiLoader } from './useGoogleApiLoader'

export const useGeoSuggest = (
  enable: boolean
): RefObject<Geosuggest | null> => {
  const geoSuggest = useRef<Geosuggest | null>(null)

  const { isLoaded, load } = useGoogleApiLoader()

  useEffect(() => {
    if (enable) {
      load()
    }
  }, [enable, load])

  useEffect(() => {
    if (isLoaded) {
      geoSuggest.current = new Geosuggest()
    }

    return (): void => {
      geoSuggest.current = null
    }
  }, [isLoaded])

  return geoSuggest
}
