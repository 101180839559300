import { FC, ReactElement, ReactNode, ReactText } from 'react'

import { get } from 'object-path'
import { UseControllerProps, useFormContext } from 'react-hook-form'

import useFormField from '~/hooks/useFormField'

import { TextFieldProps } from '../TextField'

import Geosuggest from './Geosuggest'

const getFormErrorByName = (errors: any, name: string): string | null =>
  get(errors, name)?.message || null

interface TransformSchema {
  input: (string: any) => any
  output: (string: any) => any
}

interface GeosuggestFieldCustomProps {
  loadGoogle?: boolean
  name: string
  id?: string
  transform?: TransformSchema
  label?: string
  country?: string
  types?: string[]
  isLoading?: boolean
  helperText?: ReactText | ReactElement | ReactNode | null
  placeholder?: string
  onChange?: (value) => void
  formatOptions?: (resp: any) => any
}

export type GeosuggestFieldProps = GeosuggestFieldCustomProps &
  TextFieldProps &
  UseControllerProps

const GeosuggestField: FC<GeosuggestFieldProps> = ({
  name,
  loadGoogle = false,
  id,
  transform,
  types = [],
  country = '',
  isLoading,
  rules,
  ...rest
}) => {
  const {
    formState: { errors }
  } = useFormContext()

  const {
    field: { ref, ...props },
    value,
    onChange
  } = useFormField({
    name,
    transform,
    rules
  })

  let errorMessage = getFormErrorByName(errors, name)

  if (name.includes('.')) {
    let currentError = errors
    const errorKeys = name.split('.')

    errorKeys.forEach((key) => {
      currentError = currentError?.[key]
    })

    errorMessage = currentError?.message
  }

  return (
    <Geosuggest
      {...props}
      loadGoogle={loadGoogle}
      variant="outlined"
      error={!!errorMessage}
      errorMessage={errorMessage || undefined}
      options={{
        types,
        country
      }}
      onChange={onChange}
      value={value}
      size="small"
      {...rest}
    />
  )
}

export default GeosuggestField
