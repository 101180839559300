import { useCallback, useState } from 'react'

import { Loader } from '@googlemaps/js-api-loader'

// export interface UseLoadScriptOptions extends LoaderOptions {}

export function useGoogleApiLoader(): {
  isLoaded: boolean
  loadError: Error | undefined
  load: () => void
} {
  const [isLoaded, setLoaded] = useState(false)
  const [loadError, setLoadError] = useState<Error | undefined>(undefined)

  const load = useCallback(() => {
    if (isLoaded) {
      return
    }

    const loader = new Loader({
      apiKey: process.env.GOOGLE_API_KEY || '',
      libraries: ['places'],
      language: 'en'
    })

    loader
      .load()
      .then(() => {
        setLoaded(true)
      })
      .catch((error) => {
        setLoadError(error)
      })
  }, [isLoaded])

  return { isLoaded, loadError, load }
}
