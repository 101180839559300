import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  FC,
  ReactElement
} from 'react'

import {
  TextFieldProps,
  Grid,
  MenuItem,
  Popper,
  PopperProps,
  Autocomplete
} from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import isEmpty from 'lodash.isempty'
import throttle from 'lodash.throttle'
import { UseControllerProps } from 'react-hook-form'

import Text from '~/components/base/Text'

import { useGeoSuggest } from '~/hooks/useGeoSuggest'
import { Options } from '~/services/Geosuggest'

import LocationIcon from '~/assets/icons/home/location.svg'

import TextField from '../TextField'

const componentsGeosuggest = {
  street_number: {
    key: 'short_name',
    label: 'street_number'
  },
  route: {
    key: 'long_name',
    label: 'street_name'
  },
  postal_town: {
    key: 'long_name',
    label: 'city'
  },
  locality: {
    key: 'long_name',
    label: 'city'
  },
  administrative_area_level_1: {
    key: 'short_name',
    label: 'state'
  },
  country: {
    key: 'short_name',
    label: 'country'
  },
  postal_code: {
    key: 'short_name',
    label: 'zip_code'
  }
}

interface CustomProps {
  value?: any
  loadGoogle: boolean
  onChange?: (value: any) => void
  onBlur: (value: any) => void
  options?: Options
  required?: boolean
  disabled?: boolean
  id?: string
  isOptional?: boolean
  errorMessage?: string | boolean
  formatOptions?: (resp: any) => any
}

type GeosuggestProps = CustomProps & UseControllerProps & TextFieldProps

const Geosuggest: FC<GeosuggestProps> = ({
  loadGoogle,
  value,
  onChange,
  onBlur,
  options = {},
  required,
  disabled,
  id,
  isOptional,
  label,
  errorMessage,
  helperText,
  formatOptions,
  ...rest
}) => {
  const [enable, setEnable] = useState(!!loadGoogle)
  const geosuggest = useGeoSuggest(enable)

  const [inputValue, setInputValue] = useState('')
  const [suggestOptions, setSuggestOptions] = useState([])

  const searchSuggests = useMemo(
    () =>
      throttle(async (input: any, callback: any) => {
        try {
          const resp = await geosuggest?.current?.searchSuggests(input, options)
          const result = formatOptions && formatOptions(resp)
          callback(result || resp)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('err', err)
        }
      }, 200),
    [geosuggest, options]
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setSuggestOptions([])
    }

    searchSuggests(inputValue, (results: any) => {
      if (active) {
        setSuggestOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, searchSuggests])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
      setEnable(true)
    },
    []
  )

  const handleSelect = useCallback(
    async (_e, v) => {
      let value: any = null
      const result = {}
      if (v) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        const suggest = await geosuggest?.current?.geocodeSuggest(v, options)

        if (!isEmpty(suggest)) {
          const addressArray = suggest?.gmaps?.address_components
          const isAddressLength = Boolean(addressArray.length)
          value = suggest

          if (isAddressLength) {
            addressArray.forEach((address) => {
              const addressType = address.types[0]

              if (componentsGeosuggest[addressType]) {
                const value = address[componentsGeosuggest[addressType].key]

                result[componentsGeosuggest[addressType].label] = value
              }
            })
          }
        }

        if (!isEmpty(result)) {
          value = {
            label: suggest?.label,
            location: suggest?.location,
            ...result
          }
        }
      }

      if (onChange) {
        onChange(value)
      }
    },
    [onChange, options, geosuggest]
  )

  useEffect(() => {
    if (typeof value === 'string') {
      searchSuggests(value, (results: any) => {
        if (Array.isArray(results) && results.length && onChange) {
          onChange(results[0])
        }
      })
    }
  }, [value])

  const CustomPopper = (props: PopperProps) => {
    return (
      <Popper
        {...props}
        style={{ paddingTop: '5px' }}
        placement="bottom-start"
      />
    )
  }

  return (
    <Autocomplete
      getOptionLabel={(option): string =>
        typeof option === 'string' ? option : option.label
      }
      PopperComponent={CustomPopper}
      filterOptions={(x): any => x}
      options={suggestOptions}
      autoComplete
      includeInputInList
      freeSolo
      onChange={handleSelect}
      value={value?.label ?? ''}
      id={id}
      disabled={disabled}
      renderInput={(params): ReactElement => {
        return (
          <TextField
            label={label}
            variant="outlined"
            onChange={handleChange}
            helperText={helperText}
            onBlur={(): void => {
              onBlur(value)
            }}
            required={required}
            {...params}
            {...rest}
            InputProps={{
              ...params.InputProps,
              ...rest.InputProps
            }}
            inputProps={{
              'data-required': !isOptional,
              ...params.inputProps
            }}
          />
        )
      }}
      renderOption={(props, option: any): ReactElement => {
        const matches = option.structuredFormatting.main_text_matched_substrings

        const parts = parse(
          option.structuredFormatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length
          ])
        )

        return (
          <MenuItem {...props} sx={{ minWidth: 350 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1} alignSelf="flex-end" mt={0.6}>
                <LocationIcon />
              </Grid>

              <Grid
                item
                xs={11}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {parts.map((part) => {
                  return (
                    <Text
                      component="span"
                      size={{ xs: 'body2' }}
                      fontWeight={part.highlight ? 600 : 400}
                      color="textPrimary"
                      key={part.text}
                    >
                      {part.text}
                    </Text>
                  )
                })}{' '}
                <Text
                  size={{ xs: 'body2' }}
                  component="span"
                  color="textPrimary"
                >
                  {option.structuredFormatting.secondary_text}
                </Text>
              </Grid>
            </Grid>
          </MenuItem>
        )
      }}
    />
  )
}

export default Geosuggest
